/* src/components/styles.css */
.data-card {
  background: #e0e6fb;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: start;
  margin-bottom: 16px;
  width: 31%;
  cursor: pointer;
  transition: background 0.3s;
}
.winner-card {
  background: #e7faec;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: start;
  height: 100px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: background 0.3s;
}
.winner-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.animation-badge div:first-child {
  display: flex;
  justify-content: flex-end;
}
.animation-badge svg {
  height: 70px !important;
  width: 70px !important;
}
.winner-profile .details {
  display: flex;
  /* gap: 10px; */
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.winner-card .title {
  font-size: 14px;
  font-weight: 600;
  color: #2bc155;
}

.winner-card .percentage {
  font-weight: 600;
  color: #2bc155;
  margin: 0;
}
.winner-card .icon {
  background: #e7faec;
  border-radius: 20%;
  width: 42px;
  height: 42px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2bc155;
}
.app-header .data-card {
  margin-left: 16px;
  min-width: 250px;
}

.data-card:hover {
  background: #d1d8ff;
}

.data-card .icon {
  background: #fafafb;
  border-radius: 20%;
  width: 42px;
  height: 42px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #111781c5;
}
.icon i {
  font: unset;
  font-size: 20px;
}

.data-card .details {
  display: flex;
  flex-direction: column;
}

.data-card .title {
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
}

.data-card .percentage {
  /* font-size: 20px; */
  font-weight: 600;

  color: #111781c5;
}

.user-profile {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  padding-bottom: 10px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: column;
}

.user-profile .header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.user-profile .profile-pic {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.user-profile .info {
  flex: 1;
}

.user-profile h3 {
  margin: 0;
  font-size: 24px;
  padding-top: 8px;
  color: #4a4a4a;
}

.user-profile .info p {
  margin: 4px 0;
  color: #6a6a6a;
}

.user-profile .info button {
  background: #4a90e2;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.user-profile .info button:hover {
  background: #357abf;
}

.user-profile .details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.user-profile .details .label {
  font-weight: bold;
  color: #4a4a4a;
}

.user-profile .contact,
.user-profile .validation,
.user-profile .events {
  margin-bottom: 16px;
}

.user-profile .validation div,
.user-profile .events div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.user-profile .validation a,
.user-profile .events a {
  color: #4a90e2;
  text-decoration: none;
  transition: color 0.3s;
}

.user-profile .validation a:hover,
.user-profile .events a:hover {
  color: #357abf;
}

.App {
  font-family: Arial, sans-serif;
  padding: 24px;
  background: #f5f5f5;
}

.app-header {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.app-header h1 {
  margin: 0;
  font-size: 24px;
  color: #4a4a4a;
}

.app-header span {
  /* font-size: 16px; */
  font-weight: 600;
  color: #6a6a6a;
}
.spin-wheel-details main {
  gap: 15px;
}
.message {
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
}
.message p {
  margin: 0;
}
.cards-section {
  display: flex;
  padding: 1rem;
}

.cards-section h3 {
  margin: 16px;
  font-size: 24px;
  color: #4a4a4a;
}
