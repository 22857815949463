.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content */
.popup-content {
  background: #fff;
  padding: 3rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
}
.popup-header .email-icon {
  height: 120px;
}

/* Close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
}

/* Popup header */
.popup-header {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}
.popup-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  padding-top: 10px;
}

.popup-icon {
  margin-right: 10px;
  font-size: 2em;
}

/* Popup message */
.popup-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.message-icon {
  margin-bottom: 10px;
  font-size: 2em;
  color: green;
}

.popup-message p {
  font-size: 1em;
  margin: 0;
}
.ok-button {
  padding-top: 1.5rem;
}
.ok-button button {
  width: 20%;
  height: 50px;
}

@media (max-width: 576px) {
  .ok-button button {
    width: 100%;
  }
}
