a {
  text-decoration: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-80w {
  width: 70%;
  max-width: 70% !important;
}
.header-image {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}

.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
select:required:invalid {
  color: gray;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
.text-font-style {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.react-tel-input {
  width: auto !important;
}
.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}
.react-tel-input .form-control {
  width: 110px !important;
  font-size: 14px !important;
  border-radius: 9px !important;
  border: 1px solid #f0f1f5 !important;
  padding: 18.5px 9px 18.5px 52px !important;
}
.react-tel-input .country-list {
  width: 250px !important;
}
.store-modal {
  padding: 20px;
}
.modal-style .modal-dialog {
  min-width: 610px !important;
}
.table tr:hover {
  background-color: #d8e6ff !important;
  cursor: pointer;
}
.list-btn {
  border: none;
  transition: calc(0.3s);
}
.list-btn:hover {
  border: none;
  transform: scale(1.5);
}
.store-card {
  overflow-y: auto;
  padding: 15px;
  /* width: 80%; */
}

.card-item {
  /* margin-bottom: 10px; */
  display: flex;
  align-items: flex-start;
  padding-bottom: 15px;
  gap: 20px;
  border-bottom: 1px solid lightgray;
}
.store-details {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  padding: 0.5rem;
  /* border-radius: 5px ; */
  border-bottom: 1px solid lightgray;
}
.store-details p {
  margin: 0;
}
.store-details p:first-child {
  font-weight: 600;
}
.store-img {
  height: 200px;
  min-width: 150px;
  width: 200px;
}
.store-img img {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  width: 100%;
  border-radius: 5px;
}
.card-content {
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 10px;
  width: 80%;
}
.card-content p {
  margin: 0;
}
.card-content p:first-child {
  font-weight: 600;
  margin: 0;
}
.card-content h5 {
  margin: 0;
}

.remove-btn {
  position: absolute;
  right: 0px;
  top: -2px;
}
.remove-btn button {
  height: 25px;
  width: 25px;
  border: 1px solid gray;
  border-radius: 50%;
  transition: all 0.3s;
}
.remove-btn button:hover {
  transform: scale(1.2);
  color: red;
  background-color: #ffeae6;
}
.vendor-documents {
  /* max-width: 80%; */
}
/* .vendor-documents .card {
  background-color: lightgrey;
} */
.documents-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.documents-images img {
  height: 200px;
  width: 30%;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid lightgray;
  transition: all 0.3s;
}
.pointer {
  cursor: pointer;
}
.documents-images img:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.image-view-modal div {
  height: 700px;
  width: 80vw;
}
.image-view-modal img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.image-view-modal .modal-dialog {
  max-width: none;
}
.cross {
  height: 40px;
  border-radius: 20px;
  width: 40px;
  background-color: #ecf0f3 !important;
  position: absolute;
  right: 10px;
  top: 10px;
}
.cross:hover {
  background-color: #fff !important;
}
.spin-details {
  background-color: rgba(236, 242, 250, 0.927);
  backdrop-filter: blur(20px);
  padding: 1rem;
  border-radius: 6px;
}
.profile-details {
  display: flex;
  gap: 5rem;
  align-items: center;
}

.profile-details .name-section {
  width: 35%;
}
.profile-details .name-section p {
  margin: 0;
}
.profile-details .name-section p:first-child {
  font-size: 16px;
  font-weight: 500;
}
.spin-prize-list {
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1.5rem;
}
.spin-prize-list p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}
.spin-prize-list:last-child {
  border-bottom: 0;
}
.group-modal .modal-dialog {
  min-width: 360px !important;
}

.group-modal .card {
  margin-bottom: 0px !important;
  padding: 15px !important;
}
.group-modal .close {
  top: 2px !important;
  font-size: 2rem !important;
  right: 12px !important;
  text-align: end;
}
.group-modal .modal-dialog-centered {
  min-height: 100% !important;
  height: 100% !important;
}

.group-name {
  font-size: 1.2rem;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 0;
}

/* Hide default checkbox */
.checkbox-container input {
  display: none;
}

/* Style the custom checkbox */
.checkbox-container .checkmark {
  width: 1.3rem;
  height: 1.3rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  margin-top: 6px;
  /* margin-right: 8px; */
}

/* Style the checkmark when the checkbox is checked */
.checkbox-container input:checked + .checkmark {
  background-color: #042e77;
}

/* Create the checkmark inside the checkbox */
.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked + .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 2.5px;
  width: 5px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.search-member {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 6px;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
}
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.pointer {
  cursor: pointer;
  transition: all 0.3s;
}
.pointer:hover {
  transform: scale(1.02);
}

/* //========================Offer Card Css======================================================== */
.loyaltyItem {
  background-color: #e6eeff;
  border-radius: 10px;
  padding: 14px;
  cursor: pointer;
}

.headerText {
  font-size: 16px;
  color: #000;
  margin: 0;
  padding-top: 5px;
}



.subheaderText {
  color: darkWhite;
  margin: 0;
  font-size: 12px;
  padding-bottom: 12px;
}

.darkView {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  background-color: #042e77;
  margin: -14px -14px -14px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tillText {
  margin: 0px;
  font-size: 15px;
  color: white;
}

.validText {
  font-size: 15px;
  color: white;
}
.cutleryImage {
  width: 28px;
}
.user-program-listing {
  display: flex;
  gap: 10px;
  overflow: scroll;
}
.table-column {
  color: #042e77;
  transition: all 0.3s;
}
.table-column:hover {
  transform: scale(1.5);
}

.filter-tabs {
  display: flex;
  align-items: center;

  background-color: rgba(47, 76, 221, 0.2);
  padding: 5px;
  border-radius: 8px;
  flex-wrap: nowrap;
  width: 230px;
}
.tab {
  margin: 0;
  color: #0b43f0;
  font-weight: 500;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  width: 50%;
  text-align: center;
  cursor: pointer;
}
.active-tab {
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 500;
  color: #000;
  margin: 0;
  width: 50%;
  text-align: center;
  transition: color 0.3s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out;
  /* transform: translateX(0.1s); */
}
.red-word {
  font-weight: 500;
  color: rgb(200, 32, 32);
}
.green-word {
  font-weight: 700;
  color: green;
}
.disabled-row {
  background-color: #e9ecef !important; /* Light gray background */
  color: #7d6f6c !important; /* Gray text */
  pointer-events: none !important; /* Disable clicking */
  opacity: 0.65 !important; /* Slightly fade the row */
}
